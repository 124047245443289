import React from 'react'
import './footera.css'
import { Link } from 'react-router-dom'


export default function Footera() {
    return (
        <footer className='aff '>
            <div className='amap '>
                <div className='xfc'>
                    <div className='container'>
                        <div className='form'>
                            <img src='/logo.png' alt='' />
                            <p>
                                Required Informations
                            </p>
                            <form action="submit.php" method="post" id="form">
                                <input type="text" id="name" name="name" placeholder='Name / الاسم' />

                                <input type="email" id="email" name="email" placeholder='E-mail / الايميل' />

                                <input type="tel" id="phone" name="phone" placeholder='Your Phone Number / رقم التليفون' />

                                <input type="text" id="activity" name="activity" placeholder='Activity / نوع النشاط' />

                                <input type="number" id="residence" name="residence" placeholder='Number of Residences or Investors / عدد الاقامات او المستثمرين' />

                                <textarea id="notes" name="notes" placeholder='Notes / ملاحظات'></textarea>

                                <input type="submit" value="Submit" />
                            </form>
                        </div>
                    </div>
                </div>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1242.2687233704498!2d55.30163847722248!3d25.15577871046888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69b334b7d3bb%3A0x4cc18e99b55206b1!2sDND%20Events%20L.L.C-FZ!5e0!3m2!1sar!2seg!4v1679330132381!5m2!1sar!2seg"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title='location'
                >
                </iframe>
            </div>
            <div className='axcon '>
                <div className='aportion '>
                    <div className='algsf '>
                        <img className='aimx ' src='logo.png' alt='' />
                        <a
                            href="https://www.facebook.com/damansolution?mibextid=LQQJ4d"
                            target="_blank" rel="noreferrer"
                        ><img src="fb.png" alt=""
                            /></a>
                        <a
                            href="https://instagram.com/damansolutions?igshid=YmMyMTA2M2Y="
                            target="_blank" rel="noreferrer"
                        ><img src="ig.png" alt=""
                            /></a>
                        <a
                            href="https://www.linkedin.com/in/daman-solutions-b72154267"
                            target="_blank" rel="noreferrer"
                        ><img src="li.png" alt=""
                            /></a>
                        <a
                            href="https://twitter.com/damansolutions?s=11"
                            target="_blank" rel="noreferrer"
                        ><img src="tw.png" alt=""
                            /></a>
                    </div>

                    <ul className='aful '>
                        <li className='axl '><Link to='/ar#mm' className='all '><p>الصفحة الرئيسية</p></Link></li>
                        <li className='axl '><Link to='/arServices#mm' className='all '><p>الخدمات</p></Link></li>
                        <li className='axl '><Link to='/arAboutus#mm' className='all '><p>اعرف عنا</p></Link></li>
                        <li className='axl '><Link to='/arContactus#mm' className='all '><p>تواصل معنا الاّن</p></Link></li>
                    </ul>
                </div>
                <div className='aportion '>
                    <div className='acontact '>
                        <h1>معلومات التواصل</h1>
                        <p>
                            نحن نقدم خدمات واضحة لتأسيس وإدارة أعمالك في دولة الإمارات العربية المتحدة بكل سهولة.
                            خبرتنا الواسعة في تكوين الشركات للعديد من قطاعات الأعمال ...
                        </p>
                        <p>
                            <img src='location.png' alt='location' /> مركز الأعمال 1 ، طابق الميزانين ، فندق الميدان ، ند الشبا ، دبي ، الإمارات العربية المتحدة.

                        </p>
                        <p>
                            <img src='phone.png' alt='phone' /> <a href="tel:0547807870">0547807870</a>
                        </p>
                        <p>
                            <img src='mail.png' alt='mail' /> <a href="mailto:Info@damansolutions.com">Info@damansolutions.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
